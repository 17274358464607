@import '../../styles/index';
.logo {
    max-width: 80%;
    max-height: 10vh;
}
.headerWrapper {
    width: 100%;
    padding: 5px 0 5px 0;
    display: flex;
    flex-direction: column;

    background-color: $base--bg-color;
    flex: 0;
    border-bottom: 1px solid $base--ocra;
    @include respond-to($tablet) {
        max-width: $max--desktop;
        margin: 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.navigationWrapper {
    display: flex;
}

.search {
    width: 25px;
    height: 25px;
    background-color: #e2ab59;
}

// ::: LAYOUT :::
$mobile: 'mobile';
$tablet: 'tablet';
$desktop: 'desktop';

// ::: SIZES :::
//$mobile: 'mobile';
//$tablet: 'tablet';
$max--desktop: 1420px;
$width--map-desktop: 1000px;
$height--map-desktop: 756px;

// ::: COLORS :::
$base--bg-color: #F9F8F5;
$base--dark: #212121;
$base--ocra: #E2AB59;
$base--text81: #818181;
$base--title49: #494128;


// ::: SPACERS :::
$space--5: 5px;
$space--25: 25px;
$space--35: 35px;
$space--50: 50px;

// :::::: Z-INDEXES :::::::
$over-map: 10000;

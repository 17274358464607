@import '../../../../styles/index';

.infoBox {
    width: 100%;
    margin-bottom: $space--35;
}

.title,
.text {
    font-family: 'NewCycle';
    color: $base--text81;
    font-size: 17px;
    font-weight: 300;
    margin: 5px;
}

.title {
    color: $base--title49;
    font-size: 19px;
    font-weight: 300;
    border-bottom: 1px solid $base--ocra;
}

.imageWrapper {
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

@import '../../../styles/index';

.menuItem {
    padding: 5px 10px;
    font-size: 8px;
    @include respond-to($tablet) {
        padding: 5px 5px;
        font-size: 15px;
    }
}
